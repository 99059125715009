import type { Game } from "~/routes";

export const Hangman = ({ game }: { game: Game }) => {
  const wrongGuessesCount = game.wrongGuesses.length;

  return (
    <svg
      className="mx-auto"
      width="200"
      height="200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="20" y="180" width="50" height="10" stroke="white" fill="white" />
      <line x1="45" y1="25" x2="45" y2="180" stroke="white" strokeWidth="2" />
      <line x1="30" y1="30" x2="110" y2="30" stroke="white" strokeWidth="2" />
      <line x1="100" y1="25" x2="100" y2="40" stroke="white" strokeWidth="2" />

      {wrongGuessesCount > 0 && (
        <circle width="100" height="100" cx="100" cy="50" r="10" fill="white" />
      )}
      {wrongGuessesCount > 1 && (
        <line
          x1="100"
          y1="60"
          x2="100"
          y2="110"
          stroke="white"
          strokeWidth="2"
        />
      )}

      {wrongGuessesCount > 2 && (
        <line x1="100" y1="70" x2="70" y2="70" stroke="white" strokeWidth="2" />
      )}
      {wrongGuessesCount > 3 && (
        <line
          x1="100"
          y1="70"
          x2="130"
          y2="70"
          stroke="white"
          strokeWidth="2"
        />
      )}

      {wrongGuessesCount > 4 && (
        <line
          x1="100"
          y1="110"
          x2="70"
          y2="130"
          stroke="white"
          strokeWidth="2"
        />
      )}
      {wrongGuessesCount > 5 && (
        <line
          x1="100"
          y1="110"
          x2="130"
          y2="130"
          stroke="white"
          strokeWidth="2"
        />
      )}
    </svg>
  );
};
